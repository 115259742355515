<template>
	 <!-- 云市场 -->
	<div class="plugin-list">
		<!-- <plugin-bazaar></plugin-bazaar> -->
		<div class="el-content" v-if="!show.is_buy">
			<div class="pl-title">已开通 <div class="pl-refresh" @click="refreshCloud"><i class="el-icon-refresh"></i>更新缓存</div></div>
			<div class="plugin-list-con">
				<div class="plc-item" v-for="(item,index) in buy" :key="index">
					<img class="plci-img" v-if="item.detail"  :src="item.detail.plugin_logo" alt="">
					<div>
						<div class="plci-name" v-if="item.detail" >{{item.detail.plugin_name}}</div>
						<div class="plci-sign" v-if="item.detail" >{{item.detail.plugin_desc}}</div>
						<div class="plci-buy" >已开通</div>
					</div>
				</div>
			</div>
			<div class="pl-title">未开通</div>
			<div class="plugin-list-con">
				<div class="plc-item" v-for="(item,index) in shop" :key="index">
					<img class="plci-img" v-if="item.detail"  :src="item.detail.plugin_logo" alt="">
					<div>
						<div class="plci-name" v-if="item.detail" >{{item.detail.plugin_name}}</div>
						<div class="plci-sign" v-if="item.detail" >{{item.detail.plugin_desc}}</div>
						<div class="plci-price" v-if="item.amount_actual >0">￥{{item.amount_actual/100}}</div>
						<div class="plci-buy" v-if="item.amount_actual == 0" @click="openAdhibition(item)">开通</div>
						<div class="plci-buy" v-else @click="openAdhibition(item)">立即购买</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 支付信息 -->
		<div class="el-content" v-if="show.is_buy">
			<a-form>
				<a-form-item label="插件名称">
					<span>{{form.plugin_name}}</span>
				</a-form-item>
				<a-form-item label="插件价格">
					<span v-if="form.amount_actual > 0">￥{{form.amount_actual/100}}</span>
					<span v-else>免费</span>
				</a-form-item>
				<a-form-item label="插件简介">
					<span>{{form.plugin_desc}}</span>
				</a-form-item>
				<a-form-item label="支付方式" v-if="form.amount_actual>0">
					<div class="kd-paymethod">
						<div class="kd-paymethod-li" :class="[form.pay_type=='al'?'kd-p-active':'']" @click="form.pay_type='al'">
							<i class="iconfont icon-zhifubaozhifu"  style="color: #2196F3;"></i>支付宝支付
							<img v-if="form.pay_type=='al'" class="active-img-alipay" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
						<div class="kd-paymethod-li" :class="[form.pay_type=='wx'?'kd-p-active':'']" @click="form.pay_type='wx'">
							<i class="iconfont icon-weixinzhifu" style="color: #4CAF50;"></i> 微信支付
							<img v-if="form.pay_type=='wx'" class="active-img" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
					</div>
				</a-form-item>
				
				<a-form-item label="插件简介">
					<a-space>
						<a-button type="primary" @click="pluginPay">{{form.amount_actual>0 ? '立即购买':'立即开通'}}</a-button>
						<a-button @click="show.is_buy = false">取消</a-button>
					</a-space>
				</a-form-item>
			</a-form>
		</div>
		
		<!-- 微信支付二维码弹框 -->
		<a-modal class="wx-pay-dialog" title="微信支付" v-model:visible="show.wxpay" width="400px" :footer="null">
			<div class="wx-pay-dialog-content">
				<div class="wx-pay-dialog-qrcode">
					<div class="qrcode-img">
						<img :src="wx_pay_qrcode" alt="">
					</div>
				</div>
				<div class="wx-pay-dialog-total">支付金额：{{form.amount_actual/100}}元</div>
				<div class="wx-pay-dialog-tag">请使用微信扫一扫，扫描二维码进行支付</div>
			</div>
			<div class="wx-pay-dialog-tag-1">支付完成前请不要关闭此窗口</div>
			<div style="text-align: center;">
				<a-space>
					<a-button @click="show.wxpay = false">不想支付了</a-button>
					<a-button type="primary" @click="checkMyPayd">我已支付</a-button>
				</a-space>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import pluginModel from '@/api/plugin'
import programModel from '@/api/saas/app'
// import pluginBazaar from '@/components/layout/todo/plugin_bazaar.vue'
export default{
	// components:{
	// 	pluginBazaar
	// },
	setup(){
		const state = reactive({
			buy:[],
			shop:[],
			form:{
				plugin_id:'',
				plugin_name:'',
				plugin_desc:'',
				amount_actual:'',
				pay_type:'al',	//al=支付宝 wx=微信
			},
			wx_pay_qrcode:'',	//微信支付二维码
			order_sn:'',		//生成订单号
			show:{
				is_buy:false,
				wxpay:false,
			}
		})

		getPluginList()
		
		function getPluginList(){
			pluginModel.getMyPlugin(res=>{
				state.shop = res.shop
				state.buy = res.self
			})
		}

		function openAdhibition(item){
			state.show.is_buy = true
			state.form.plugin_id = item.detail.plugin_id
			state.form.plugin_name = item.detail.plugin_name
			state.form.plugin_desc = item.detail.plugin_desc
			state.form.amount_actual = item.amount_actual
			state.form.amount_origin = item.amount_origin
		}

		function pluginPay(){
			let { form } =state
			pluginModel.createPluginOrder({plugin_id:form.plugin_id,plugin_name:form.plugin_name},res=>{
				state.order_sn = res.order_sn
				if( res.type == 'create_order'){
					pluginModel.pluginOrderPay(res.order_sn,form.pay_type,pay_res=>{
						state.show.wxpay = true
						state.wx_pay_qrcode = pay_res.pay
					})
				}else{
					getPluginList()
					state.show.is_buy = false
					location.reload()
				}
			}) 
		}

		function checkMyPayd(){
			pluginModel.pluginOrderCheck(state.order_sn,res=>{
				state.show.wxpay = false
				state.show.is_buy = false
				refreshCloud()
			})
		}

		const refreshCloud = programModel.cloudRefreshCache(()=>{})

		return{
			...toRefs(state),
			openAdhibition,
			pluginPay,
			checkMyPayd,
			refreshCloud
		}
	}
}
</script>

<style lang="scss">
	
	.plugin-list{
		.plugin-list-con{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			
			.plc-item{
				width: 240px;
				height: 90px;
				border: 1px solid #f4f4f4;
				margin:0 20px 20px 0;
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: 13px;
				
				.plci-img{
					width: 80px;
					height: 80px;
					margin:0 5px;
				}
				
				.plci-sign{
					color: #999;
				}
				.plci-price{
					font-size: 16px;
					color: #f72424;
					font-weight: bold;
				}
				.plci-buy{
					color: #f72424;
				}
			}
			.plc-item:hover{
				border: 1px solid #55aaff;
			}
		}
		
		.pl-title{
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
		}
	}
	
	.kd-paymethod{
		display: flex;
		
		&-li{
			width: 150px;
			height: 50px;
			border: 1px solid #e4e4e4;
			margin-right: 10px;
			text-align: center;
			font-size: 18px;
			cursor: pointer;
			
			.iconfont{
				font-size: 22px;
				margin-right: 5px;
			}
			
			.active-img{
				position: absolute;
				width: 24px;
				height: 20px;
				margin-top: 28px;
    			margin-left: 11px;
			}
			.active-img-alipay{
				position: absolute;
				width: 24px;
				height: 20px;
				margin-top: 28px;
				margin-left: 3px;
			}
		}
		
		.kd-p-active{
			border: 2px solid #ff4544;
		}
	}
	
	.wx-pay-dialog{
		.el-dialog--center{
			height: 500px;
			
			.el-dialog__body{
				padding-bottom: 0;
			}
		}
		&-content{
			text-align: center;
			border-bottom: 1px dashed #e6e6e6;
			padding-bottom: 20px;
		}
		&-qrcode{
			width: 100%;
			height: 200px;
			padding: 10px;
			display: flex;
			justify-content: center;
			
			.qrcode-img{
				width: 160px;
				height: 160px;
				box-shadow: 1px 2px 10px #bdd8f1;
			}
			img{
				width: 150px;
				height: 150px;
			}
		}
		&-total{
			color: orange;
			font-size: 16px;
			font-weight: bold;
			margin-top: 20px;
		}
		&-tag{
			font-size: 12px;
			color: #868686;
			margin-top: 10px;
		}
		&-tag-1{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #868686;
			height: 50px;
			line-height: 50px;
		}
	}
	
	.pl-refresh{
		cursor: pointer;
		color: #999;
		font-size: 14px;
		font-weight: 100;
	}
</style>
